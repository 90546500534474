import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Headline } from 'src/shared/components/index';
import { CustomerForm, CustomerPreview, getCustomerSelector } from '@modules/customer';

export const CustomerInformation = () => {
  const customer = useSelector(getCustomerSelector);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleEdit = () => setIsEdit(true);
  const handleCancel = () => setIsEdit(false);

  return (
    <div>
      <Headline>
        Konto <span>Informationen</span>
      </Headline>
      <div className="shadow-2xl rounded-[3rem] p-[2.5rem]">
        {customer && isEdit ? (
          <CustomerForm customer={customer} handleCancel={handleCancel} />
        ) : (
          <CustomerPreview customer={customer} handleEdit={handleEdit} />
        )}
      </div>
    </div>
  );
};
