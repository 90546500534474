import { KTSVG } from '../../helpers';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'src/shared/hooks/useClickOutside';

export type TooltipProps = {
  children: ReactNode;
  title: string;
  icon: string;
};

export const Tooltip = ({ children, title, icon }: TooltipProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => setIsOpen(false);

  const handleEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  useClickOutside(ref, () => {
    handleClose();
  });

  useEffect(() => {
    window.addEventListener('keydown', handleEsc, false);

    return () => {
      window.removeEventListener('keydown', handleEsc, false);
    };
  });

  return (
    <div ref={ref} className="tooltip inline-flex">
      <button
        className="inline-flex justify-start items-center gap-[0.5rem] font-bold"
        onClick={toggleModal}
      >
        <span className="hover:underline">{title}</span>
        <KTSVG path={icon} className="tooltip-svg" />
      </button>
      {isOpen && (
        <div className="tooltip-content" onClick={toggleModal}>
          {children}
        </div>
      )}
    </div>
  );
};
