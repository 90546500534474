import { KTSVG } from '@helpers/index';
import { ReactNode } from 'react';

type DetailsProps = {
  headline: string;
  icon: string;
  children: ReactNode;
};

export const Details = ({ headline, icon, children }: DetailsProps) => {
  return (
    <div className="flex items-start gap-[3.5px]">
      <div>
        <KTSVG path={icon} />
      </div>
      <div className="ml-[.5rem]">
        <h4 className="font-bold text-[1.8rem]">{headline}</h4>
        <div className="leading-relaxed text-[1.6rem] mt-[.5rem]">{children}</div>
      </div>
    </div>
  );
};
