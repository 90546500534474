// TODO: mode to .d.ts
type ButtonSize = 'small' | 'medium' | 'large';

type ButtonSpinnerProps = {
  size: ButtonSize;
};

export const ButtonSpinner = ({ size }: ButtonSpinnerProps) => (
  <span className={`button-spinner button-spinner-${size}`} />
);
