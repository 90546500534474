import { useIntl } from 'react-intl';
import { PageTitle } from '../../../shared/layout/core';
import { DashboardPage } from '../../modules/dashboard/DashboardPage';

export const DashboardWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  );
};
