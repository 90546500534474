import { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';
import { KTSVG } from '@helpers/index';
import { ButtonSpinner } from './ButtonSpinner';

type ButtonSize = 'small' | 'medium' | 'large';

type ButtonProps = {
  id?: string;
  children?: ReactNode;
  variant?: 'primary' | 'secondary' | 'outline' | 'simple' | 'underline';
  type?: 'button' | 'submit' | 'reset';
  size?: ButtonSize;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  iconPosition?: 'before' | 'after';
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
  children,
  onClick,
  variant = 'primary',
  type = 'button',
  size = 'medium',
  color = 'primary',
  disabled = false,
  loading = false,
  id,
  icon,
  iconPosition,
  ...rest
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      {...rest}
      id={id}
      className={clsx(`rounded-[2rem] font-bold button-${size}`, {
        'text-white bg-primary': variant === 'primary',
        'text-white bg-secondary': variant === 'secondary',
        'text-primary border border-primary': variant === 'outline',
        'underline !text-light-600 hover:bg-white !p-0': variant === 'underline',
        'text-secondary': color === 'secondary',
        'inline-flex items-center gap-[.5rem]': icon,
        'p-0': variant === 'simple',
        'flex-row-reverse': icon && iconPosition === 'before',
      })}
      type={type}
      onClick={onClick}
    >
      {loading ? (
        <ButtonSpinner size={size} />
      ) : (
        <>
          {children}
          {icon && <KTSVG path={icon} />}
        </>
      )}
    </button>
  );
};
