import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { AsideMenuMain } from './AsideMenuMain';
import {
  DrawerComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../../assets/ts/components';
import { Link } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';

type Props = {
  asideMenuCSSClasses: string[];
};

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
      ScrollComponent.reinitialization();
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, 50);
  }, [pathname]);

  return (
    <div
      id="kt_aside_menu_wrapper"
      ref={scrollRef}
      className="mb-12 aside-menu hover-scroll-overlay-y"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-wrappers="#kt_aside_menu"
      data-kt-scroll-offset="0"
    >
      <div
        id="#kt_aside_menu"
        data-kt-menu="true"
        className={clsx(
          'menu d-flex flex-col text-kt-primary font-extrabold',
          asideMenuCSSClasses.join(' '),
        )}
      >
        <AsideMenuMain />
      </div>
      <Link
        to="logout"
        className="flex lg:hidden p-[2rem] items-center gap-[1.2rem] font-bold text-[1.6rem] text-secondary"
      >
        <KTSVG path={toAbsoluteUrl('/media/svg/misc/logout.svg')} />
        Abmelden
      </Link>
    </div>
  );
};

export { AsideMenu };
