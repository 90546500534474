import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
} from './actionTypes';
import {
  FetchCustomerRequest,
  FetchCustomerSuccess,
  FetchCustomerSuccessPayload,
  FetchCustomerFailure,
  FetchCustomerFailurePayload,
  UpdateCustomerRequest,
  UpdateCustomerRequestPayload,
  UpdateCustomerSuccess,
  UpdateCustomerSuccessPayload,
  UpdateCustomerFailure,
  UpdateCustomerFailurePayload,
} from './types';

export const fetchCustomerRequest = (): FetchCustomerRequest => ({
  type: FETCH_CUSTOMER_REQUEST,
});

export const fetchCustomerSuccess = (
  payload: FetchCustomerSuccessPayload,
): FetchCustomerSuccess => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload,
});

export const fetchCustomerFailure = (
  payload: FetchCustomerFailurePayload,
): FetchCustomerFailure => ({
  type: FETCH_CUSTOMER_FAILURE,
  payload,
});

export const updateCustomerRequest = (
  payload: UpdateCustomerRequestPayload,
): UpdateCustomerRequest => ({
  type: UPDATE_CUSTOMER_REQUEST,
  payload,
});

export const updateCustomerSuccess = (
  payload: UpdateCustomerSuccessPayload,
): UpdateCustomerSuccess => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload,
});

export const updateCustomerFailure = (
  payload: UpdateCustomerFailurePayload,
): UpdateCustomerFailure => ({
  type: UPDATE_CUSTOMER_FAILURE,
  payload,
});
