import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCustomerSelector } from 'src/app/modules/customer/core/selectors';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { checkIsActive } from '../../../../helpers';

export const AsideProfile = () => {
  const link = '/profile';
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, link);

  const customer = useSelector(getCustomerSelector);

  return (
    <div className="hidden aside-profile lg:block">
      <div className="mx-auto aside-avatar">
        <span className="avatar">
          {customer?.firstName?.charAt(0)}
          {customer?.lastName?.charAt(0)}
        </span>
      </div>
      <div className="flex flex-col items-center justify-center text-white">
        <p className="text-lg font-semibold aside-title">
          {customer?.first_name} {customer?.last_name}
        </p>
        <div className="flex items-center font-semibold">
          <Link
            className={clsx('aside-link flex items-center text-lg uppercase', {
              'text-black': !isActive,
            })}
            to={link}
          >
            <span>Profil</span>
          </Link>
          <Link to="logout" className="flex justify-center font-semibold uppercase aside-link">
            Abmelden
          </Link>
        </div>
      </div>
    </div>
  );
};
