import {
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILURE,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
} from './actionTypes';

import { PaymentMethodsActions, PaymentMethodsState } from './types';

const initialState: PaymentMethodsState = {
  pending: false,
  data: [],
  error: null,
};

export const paymentMethodsReducer = (state = initialState, action: PaymentMethodsActions) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.paymentMethods.data,
        error: null,
      };
    case FETCH_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };

    case UPDATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case UPDATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.paymentMethods && action.payload.paymentMethods.data
            ? action.payload.paymentMethods.data
            : [],
      };
    default:
      return {
        ...state,
      };
  }
};
