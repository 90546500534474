import { ScrollTop } from '../ScrollTop';
import { Copyright } from './copyright/Copyright';
import { FooterNavigation } from './navigation/FooterNavigation';
import { FooterContent } from './content/FooterContent';

const Footer = () => {
  return (
    <div className="footer relative bg-light-100" id="kt_footer">
      <div className="footer-content flex justify-evenly lg:gap-8 mt-[40px] flex-col-reverse xl:flex-row">
        {/* TODO: Restore Footer Content */}
        {/* <FooterContent />
        <FooterNavigation /> */}
        <ScrollTop />
      </div>
      <Copyright />
    </div>
  );
};

export { Footer };
