import {
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_FAILURE,
  FETCH_PAYMENT_METHODS_SUCCESS,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
} from './actionTypes';
import {
  FetchPaymentMethodsRequest,
  FetchPaymentMethodsSuccess,
  FetchPaymentMethodsSuccessPayload,
  FetchPaymentMethodsFailure,
  FetchPaymentMethodsFailurePayload,
  UpdatePaymentMethodRequest,
  UpdatePaymentMethodRequestPayload,
  UpdatePaymentMethodSuccess,
  UpdatePaymentMethodSuccessPayload,
  UpdatePaymentMethodFailure,
  UpdatePaymentMethodFailurePayload,
} from './types';

export const fetchPaymentMethodsRequest = (): FetchPaymentMethodsRequest => ({
  type: FETCH_PAYMENT_METHODS_REQUEST,
});

export const fetchPaymentMethodsSuccess = (
  payload: FetchPaymentMethodsSuccessPayload,
): FetchPaymentMethodsSuccess => ({
  type: FETCH_PAYMENT_METHODS_SUCCESS,
  payload,
});

export const fetchPaymentMethodsFailure = (
  payload: FetchPaymentMethodsFailurePayload,
): FetchPaymentMethodsFailure => ({
  type: FETCH_PAYMENT_METHODS_FAILURE,
  payload,
});

export const updatePaymentMethodRequest = (
  payload: UpdatePaymentMethodRequestPayload,
): UpdatePaymentMethodRequest => ({
  type: UPDATE_PAYMENT_METHOD_REQUEST,
  payload,
});

export const updatePaymentMethodSuccess = (
  payload: UpdatePaymentMethodSuccessPayload,
): UpdatePaymentMethodSuccess => ({
  type: UPDATE_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const updatePaymentMethodFailure = (
  payload: UpdatePaymentMethodFailurePayload,
): UpdatePaymentMethodFailure => ({
  type: UPDATE_PAYMENT_METHOD_FAILURE,
  payload,
});
