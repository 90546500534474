import { Link } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { Divider } from '../../Divider';

export function AsideProfileMobile() {
  return (
    <>
      <div className="flex items-center gap-[1.2rem] lg:hidden p-[2rem] pointer uppercase font-bold text-[1.6rem] text-primary">
        <div className="w-[2rem]">
          <KTSVG path={toAbsoluteUrl('/media/svg/aside/paw.svg')} />
        </div>
        <Link to="profile">Profil</Link>
      </div>
      <Divider cssClasses={['lg:hidden']} />
    </>
  );
}
