import clsx from 'clsx'

export type DividerProps = {
  title?: string
  cssClasses?: string[]
}

const Divider = ({title, cssClasses}: DividerProps) => {
  return (
    <div className={clsx('divider', cssClasses?.join(' '))}>{title && <span>{title}</span>}</div>
  )
}

export {Divider}
