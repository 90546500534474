import { createSelector } from 'reselect';

import { RootState } from '../../../../store/store';

const getPending = (state: RootState) => state.customer.pending;

const getCustomer = (state: RootState) => state.customer.data;

const getError = (state: RootState) => state.customer.error;

export const getCustomerSelector = createSelector(getCustomer, (customer) => customer);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
