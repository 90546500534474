import {useEffect, RefObject} from 'react'

export function useClickOutside<T extends HTMLElement>(ref: RefObject<T>, handler: VoidFunction) {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref && ref.current && !ref.current.contains(event.target as Node)) {
      handler()
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
