import { createSelector } from 'reselect';

import { RootState } from '../../../../store/store';

const getPending = (state: RootState) => state.orders.pending;

const getOrders = (state: RootState) => state.orders.data;

const getError = (state: RootState) => state.orders.error;

export const getOrdersSelector = createSelector(getOrders, (orders) => orders);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
