export const Copyright = () => {
  return (
    <div className='flex justify-between order-2 text-white bg-primary text-[12px] lg:text-[14px] font-medium px-[15px] lg:px-28 py-7'>
      <div>
        <a href='https://www.hellobello.at/' className=' text-hover-primary'>
          HelloBello &copy;
        </a>
        <span className=''> {new Date().getFullYear()}</span>
      </div>
      <div>
        <p>WENN HUNDE KOCHEN KÖNNTEN</p>
      </div>
    </div>
  )
}
