import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
} from './actionTypes';

import { CustomerActions, CustomerState } from './types';

const initialCustomerState: CustomerState = {
  pending: false,
  data: null,
  error: null,
};

export const customerReducer = (state = initialCustomerState, action: CustomerActions) => {
  switch (action.type) {
    case FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.customer.data,
        error: null,
      };
    case FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error,
      };
    case UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          ...action.payload.customer,
        },
        error: null,
      };
    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.customer && action.payload.customer.data
            ? action.payload.customer.data
            : null,
      };
    default:
      return {
        ...state,
      };
  }
};
