import {
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_FAILURE,
  FETCH_ADDRESSES_SUCCESS,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,
  CREATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_FAILURE,
} from './actionTypes';
import {
  FetchAddressesRequest,
  FetchAddressesSuccess,
  FetchAddressesSuccessPayload,
  FetchAddressesFailure,
  FetchAddressesFailurePayload,
  UpdateAddressRequest,
  UpdateAddressRequestPayload,
  UpdateAddressSuccess,
  UpdateAddressSuccessPayload,
  UpdateAddressFailure,
  UpdateAddressFailurePayload,
  CreateAddressRequest,
  CreateAddressRequestPayload,
  CreateAddressSuccess,
  CreateAddressSuccessPayload,
  CreateAddressFailure,
  CreateAddressFailurePayload,
} from './types';

export const fetchAddressesRequest = (): FetchAddressesRequest => ({
  type: FETCH_ADDRESSES_REQUEST,
});

export const fetchAddressesSuccess = (
  payload: FetchAddressesSuccessPayload,
): FetchAddressesSuccess => ({
  type: FETCH_ADDRESSES_SUCCESS,
  payload,
});

export const fetchAddressesFailure = (
  payload: FetchAddressesFailurePayload,
): FetchAddressesFailure => ({
  type: FETCH_ADDRESSES_FAILURE,
  payload,
});

export const updateAddressRequest = (
  payload: UpdateAddressRequestPayload,
): UpdateAddressRequest => ({
  type: UPDATE_ADDRESS_REQUEST,
  payload,
});

export const updateAddressSuccess = (
  payload: UpdateAddressSuccessPayload,
): UpdateAddressSuccess => ({
  type: UPDATE_ADDRESS_SUCCESS,
  payload,
});

export const updateAddressFailure = (
  payload: UpdateAddressFailurePayload,
): UpdateAddressFailure => ({
  type: UPDATE_ADDRESS_FAILURE,
  payload,
});

export const createAddressRequest = (
  payload: CreateAddressRequestPayload,
): CreateAddressRequest => ({
  type: CREATE_ADDRESS_REQUEST,
  payload,
});

export const createAddressSuccess = (
  payload: CreateAddressSuccessPayload,
): CreateAddressSuccess => ({
  type: CREATE_ADDRESS_SUCCESS,
  payload,
});

export const createAddressFailure = (
  payload: CreateAddressFailurePayload,
): CreateAddressFailure => ({
  type: CREATE_ADDRESS_FAILURE,
  payload,
});
